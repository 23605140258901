import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Groups,
  InviteNow,
  InviteLater,
  Institution,
  Icon,
  Button,
  BackButton,
} from "./styles"
import { Body, Buttons } from "../shared-styles"

const Credit = ({ nextStep, previousStep, action, goToStep }) => {
  const [credit, setCredit] = useState(false)
  const [individual, setIndividual] = useState(false)

  function submit() {
    action({ credit: credit ? "yes" : "no" })
    if (!credit) {
      navigate("/form?confirmation")
      return goToStep(6)
    }
    navigate("/form?institution")
    return nextStep()
  }

  function active(type) {
    if (type === "yes") {
      setCredit(true)
      setIndividual(false)
    } else if (type === "no") {
      setIndividual(true)
      setCredit(false)
    }
  }

  return (
    <Section>
      <Body>
        <Header>
          <Profile>
            <User src={Photo} />
          </Profile>
          <Label>
            <p>A solicitação de crédito Veriza é em grupo. Você já pegou crédito em grupo anteriormente?</p>
          </Label>
        </Header>
        <Form>
          <Groups>
            <InviteNow active={credit} onClick={() => active("yes")}>
              <Icon>😉</Icon>
              <p>Sim</p>
            </InviteNow>
            <InviteLater
              active={individual}
              onClick={() => active("no")}
            >
              <Icon>😑</Icon>
              <p>Não</p>
            </InviteLater>
          </Groups>
        </Form>
      </Body>
      <Buttons>
        <BackButton onClick={previousStep}>Voltar</BackButton>
        <Button disabled={!credit && !individual} onClick={() => submit()}>
          Próximo
        </Button>
      </Buttons>
    </Section>
  )
}

export default Credit
