import React from 'react';

import { Container, Content } from './styles';

function Message({ children, ...props }) {
  return (
    <Container {...props}>
      <Content>
        <p>{children}</p>
      </Content>
    </Container>
  )
}

export default Message;
