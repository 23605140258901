import React from "react"

import Logo from "../../images/logo-white.png"
import VerizaIcon from "../../images/veriza-icon-secundaria.png"
import SuccessIcon from "../../images/success.svg"
import Balloon from "../../images/balao-grande.svg"
import InstaLogo from "../../images/instagram-shape.png"
import TwitterIcon from "../../images/twitter-icon-white.png"
import FacebookIcon from "../../images/facebook-icon-white.png"
import LinkedinIcon from "../../images/linkedin-icon-white.png"
import GooglePlayImg from "../../images/googleplay.png"

import {
  Container,
  Section,
  Header,
  Image,
  ImageMobile,
  Center,
  Icon,
  Title,
  Description,
  Click,
  Stores,
  Button,
  StoreContainer,
  Instagram,
  InstagramText,
  BalloonContainer,
  BalloonLogo,
  SocialContainer,
  SocialIcons,
  GooglePlay
} from "./styles"

const Success = () => {
  return (
    <Container fluid>
      <Section>
        <Header>
          <a href="/">
            <Image src={Logo} />
            <ImageMobile src={VerizaIcon} />
          </a>
        </Header>
        <Center>
          <Title>Você está cadastrado!</Title>
          <Description>
            Para continuar com a solicitação de crédito
            <br />
            <strong>baixe o nosso aplicativo.</strong>
          </Description>
          <Click>
            <p className="animated infinite bounce">👇</p>
          </Click> 
          <StoreContainer>
            <a href="https://play.google.com/store/apps/details?id=br.com.veriza.app">
              <GooglePlay src={GooglePlayImg}/>
            </a>
          </StoreContainer>
          
          {/* <InstaContainer>
            <BalloonContainer>
              <img src={Balloon} />
              <BalloonLogo src={InstaLogo} />
            </BalloonContainer>
            <a href="https://www.instagram.com/verizapravoce/">
              <Instagram>
                <InstagramText>
                  Nos siga no Instagram!<br />
                  <strong>@verizapravoce</strong>
                </InstagramText>
              </Instagram>
            </a>
          </InstaContainer> */}
          <SocialContainer>
            <a href="https://www.linkedin.com">
              <SocialIcons src={LinkedinIcon} width={"25px"} height={"25px"}/>
            </a>
            <a href="https://www.instagram.com/verizapravoce/">
              <SocialIcons src={InstaLogo} width={"24px"} height={"24px"}/>
            </a>
            <a href="https://www.facebook.com/verizapravoce/?ref=br_rs">
              <SocialIcons src={FacebookIcon} width={"24px"} height={"24px"}/>
            </a>
          </SocialContainer>
          <Stores>
            <Button href="/">
              <p>Voltar para o início</p>
            </Button>
          </Stores>
        </Center>
      </Section>
    </Container>
  )
}

export default Success
