import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blueLight};
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  
  &::after{
    content: '';
    position: absolute;
    top: -12px;
    left: calc(50% - 12px);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${({ theme }) => theme.colors.blueLight};
    clear: both;
  }
  
  p{
    padding: 12px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.charcoal};
  
    strong{      
      font-weight: bold;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding-top: 12px;
`;
