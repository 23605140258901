import React, { useState } from "react"
import { navigate } from "gatsby"
import Message from "../../components/Message"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Button,
  BackButton,
} from "./styles"

import { Body, Buttons } from "../shared-styles"

import personIcon from '../../images/person.svg'
import plusCircle from '../../images/plus-circle.svg'
import Photo from "../../images/consultor.png"

function Group({ previousStep, nextStep, user }) {

  const next = () => {
    navigate("/form?indication")
    nextStep()
  }

  const back = () => {
    navigate("/form?confirmation")
    previousStep()
  }

  return (
    <Section>
      <Body>
        <Header>
          <Profile>
            <User src={ Photo }/>
          </Profile>
          <Label><p>Legal! Vamos indicar <span>pelo menos</span> 2 parceiros?</p></Label>
        </Header>
        <Form>
          <div className="card">
            <div className="info">
              <img src={personIcon} />
              <span>Você</span>
            </div>
            <h3 className="name">{ user && user.name}</h3>
          </div>
          <button className="add-partner" onClick={next}>
            <span>Indicar parceiro</span>
            <img src={plusCircle} />
          </button>
          <Message style={{ marginBottom: 60 }}>
            <strong>Lembre-se:</strong> Você é responsável pelo crédito dos seus parceiros e vice-versa. Priorize pessoas conhecidas! 😉
          </Message>
        </Form>
      </Body>
      <Buttons>
        <BackButton onClick={ back }>Voltar</BackButton>
      </Buttons>
    </Section>
  )
}

export default Group;
