import styled from "styled-components"

export const Container = styled.div`
  height: 64px;
  background-color: ${props => props.theme.colors.redLight};
  margin-top: 12px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 20px;
`

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #d34407;
  margin-left: 10px;
`
