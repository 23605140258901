import React, { useState } from "react"
import { navigate } from 'gatsby'
import PartnerForm from "../../components/PartnerForm"

import {
  Section,
  Form,
  Button,
  BackButton,
  Content,
} from "./styles"
import { Body, Buttons } from "../shared-styles"

const Indication = ({ previousStep, lastStep, deal }) => {
  const [partners, setPartners] = useState([{ id: Math.random(), done: false }]);

  const handleSuccess = () => {
    const newPartners = partners.map((partner) => ({
      ...partner,
      done: true,
    }));

    newPartners.push({
      id: Math.random(),
      done: false,
    });

    setPartners(newPartners);
  };

  const handleDelete = (id) => {
    const newPartners = partners.filter((partner) => partner.id !== id);
    setPartners(newPartners);
  };

  const next = () => {
    navigate("/form?success")
    lastStep()
  }

  const back = () => {
    navigate("/form?group")
    previousStep()
  }

  return (
    <Section>
      <Body style={{ flex: 1, overflowY: 'auto' }}>
        <Form>
          <Content>
            {partners.map((partner, index) => (
              <PartnerForm
                key={partner.id}
                onDelete={handleDelete}
                onSuccess={handleSuccess}
                item={partner}
                number={index + 1}
                deal={deal}
              />
            ))}
          </Content>
        </Form>
      </Body>
      <Buttons>
        <BackButton onClick={back}>Voltar</BackButton>
        {partners.length > 2 && <Button onClick={next}>
          Próximo
        </Button>}
      </Buttons>
    </Section>
  )
}

export default Indication
