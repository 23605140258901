import styled from "styled-components"
import media from "styled-media-query"
import { FiChevronDown } from 'react-icons/fi'
import ReactLoading from "react-loading"

export const Section = styled.section`
  display: flex;
  height: calc(100vh - 78px);
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan("medium")`
    width: 450px;
    margin: auto;
    justify-content: flex-start;
  `}
`

export const Profile = styled.div`
  margin-top: 50px;
  margin-left: 30px;
  width: 60px;
  height: 60px;

  ${media.lessThan("medium")`
    width: 60px;
    height: 60px;
    margin-top: 25px;
    margin-left: 28px;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    align-items: center;
    flex-direction: row;
  `}
`

export const User = styled.img``

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 108px;
`

export const Loading = styled(ReactLoading).attrs({
  type: 'spin',
  color: '#135434',
  height: 24,
  width: 24
})`
  right: 16px;
  margin-top: 16px;
  margin-bottom: 30px;
  align-self: center;
`;

export const Label = styled.div`
  margin-top: 12px;
  margin-left: 30px;
  margin-right: 30px;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  span {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  ${media.lessThan("medium")`
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 28px;
    width: 61%;
  `}
`

export const Button = styled.button`
  width: 200px;
  height: 48px;
  border-radius: 10px;
  border-style: outset;
  border: none;
  background-color: ${props => props.theme.colors.green};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  position: relative;

  &:disabled {
    background-color: ${props => props.theme.colors.greenLighter};
    opacity: 0.6;
  }

  :hover {
    background-color: ${props => props.theme.colors.greenDark};
  }
`

export const BackButton = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  cursor: pointer;
`

export const Alert = styled.div`
  width: 100%;
  padding: 14px 16px;
  background-color: rgba(44, 45, 44, 0.08);
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AlertLabel = styled.p`
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.charcoal};
`;

export const AlertValue = styled.strong`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 800;
`;

export const Info = styled.div`
  border: 1px solid rgba(44, 45, 44, 0.3);
  border-radius: 10px;
  margin-top: 24px;
  width: 100%;
`;

export const InfoHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  p{
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    color: ${({ theme }) => theme.colors.charcoal}
  }
  
  button{
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.charcoal};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    transition: all 250ms ease-in-out;
    
    &:active{
      background-color: transparent;
    }
    
    &.expanded{
      transform: rotate(-180deg);
    }
  }

`;

export const InfoContent = styled.div`
  padding: 14px 16px;
  
  p{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(44,45,44,0.8);
  }
`;

export const Icon = styled(FiChevronDown)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.charcoal};
`;
