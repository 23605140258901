import * as Yup from 'yup';

export const initialValues = {
  name: '',
  phone: '',
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo nome é obrigatório'),
  phone: Yup.string().required('O campo telefone é obrigatório'),
});
