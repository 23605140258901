import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  BackButton,
} from "./styles"
import { Body, Buttons } from "../shared-styles"

const About = ({ lending, nextStep, action }) => {
  const [name, setName] = useState("")

  function submit(e) {
    e.preventDefault()
    action({ name })
    navigate("/form?phone", { state: {value: lending}})
    nextStep()
  }

  return (
    <form onSubmit={submit}>
      <Section>
        <Body>
          <Header>
            <Profile>
              <User src={Photo} />
            </Profile>
            <Label><p>Queremos te conhecer. <span>Qual é o seu nome?</span></p></Label>
          </Header>
          <Form>
            <Input
              placeholder="Digite o seu nome completo"
              onChange={({ target }) => setName(target.value)}
            ></Input>
          </Form>
        </Body>
        <Buttons>
          <BackButton to="/" style={{ textDecoration: "none" }}>
            Voltar
          </BackButton>
          <Button type="submit" disabled={name.length < 4} >
            Próximo
          </Button>
        </Buttons>
      </Section>
    </form>
  )
}

export default About
