import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"

export const Container = styled(GridContainer)`
  background-color: ${props => props.theme.colors.greenDarker};
  height: 100vh;
`

export const Section = styled.section`
  padding-top: 45px;

  ${media.greaterThan("medium")`
    padding-top: 118px;
  `};
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  width: 187px;
  height: 46px;
  src: url(${props => props.srcMobile});

  ${media.lessThan("medium")`
    display: none;
  `};
`

export const ImageMobile = styled.img`
  width: 52px;
  height: 50px;
  src: url(${props => props.src});

  ${media.greaterThan("medium")`
    display: none;
  `};
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  align-content: center;
  margin: auto;

  ${media.greaterThan("medium")`
    margin-top: 80px;
    width: 381px;
  `};
`

export const Icon = styled.img`
  src: url(${props => props.src});
`

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 800;
  line-height: 1.28;
  text-align: center;
  color: ${props => props.theme.colors.offWhite};
  margin-top: 20px;
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.2px;
  text-align: center;
  color: ${props => props.theme.colors.offWhite};
  margin-top: 12px;
  margin-bottom: 30px;

  strong {
    font-weight: bold;
  }
`

export const Click = styled.div`
  margin-top: 32px;
  margin-bottom: 30px;
  text-align: center;

  p {
    font-size: 17px;
  }
`

export const StoreContainer = styled.div`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }

  ${media.greaterThan("medium")`
    margin-top: 30px;
    width: 381px;
  `};
`

export const BalloonContainer = styled.div`
  position: relative;
  z-index: 1;
  left: 10px;
  top: 12px;
  width: 64px;
  height: 54px;
  justify-content: center;
  align-items: center;

  ${media.lessThan("medium")`
    left: 5px;
  `};
  
`

export const BalloonLogo = styled.img`
  width: 26px;
  height: 26px;
  position: relative;
  z-index: 1;
  left: 19px;
  bottom: 52px;
  src: url(${props => props.src});
`

export const Instagram = styled.div`
  background-color: #EDF7EA;
  width: 308px;
  height: 85px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 34px;

  ${media.lessThan("medium")`
    right: 44px;
  `};

`

export const InstagramText =styled.p`
  padding-top: 20px;
  margin-left: 55px;
  margin-top: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${props => props.theme.colors.charcoal};
  opacity: 0.8;

  strong {
    font-family: Poppins;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: ${props => props.theme.colors.greenDarker};
  }
`

export const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-left: 91px;
  margin-right: 91px;
  margin-bottom: 40px;
  
  ${media.greaterThan("medium")`
    margin-bottom: 88px;
  `};
`

export const SocialIcons = styled.img`
  src: url(${props => props.src});
  width: ${props => props.width};
  height: ${props => props.height};
  color: ${props => props.theme.colors.white};
`

export const Stores = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`

export const GooglePlay = styled.img`
  width: 202px;
  height: 64px;
  src: url(${props => props.src});
`

export const Button = styled.a`
  display: flex;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.white};
  width: 319px;
  height: 48px;
  background-color: ${props => props.theme.colors.greenDarker};
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  p {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background-color: ${props => props.theme.colors.greenDarker};
    opacity: 0.5;
  }
`
