import styled from 'styled-components';
import TextInput from "../TextInput"
import ReactLoading from "react-loading"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`;

export const Label = styled.span`
  display: inline-flex;
  font-size: 18px;
  font-family: Poppins;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: ${({ theme }) => theme.colors.charcoal};
`;

export const ConfirmationButton = styled.button`
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.greenDarker};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  span{
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.greenDarker};
  }
`;

export const Loading = styled(ReactLoading).attrs({
  type: 'spin',
  color: '#135434',
  height: 20,
  width: 20
})`
  position: absolute;
  right: 16px;
`;



export const Input = styled(TextInput)`
  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Touch = styled.button`
  display: flex;
  height: 30px;
  width: 100px;
  padding-horizontal: 14px;
  border-radius: 5px;
  border-width: 1px;
  background-color: white;
  border-color: ${({ theme }) => theme.colors.greenDarker};
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ButtonLabel = styled.span`
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.greenDarker};
  margin-top: 2px;
  margin-left: 4px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  border: 1px solid rgba(44,45,44,0.2);
  border-radius: 10px;
  padding: 12px 16px;
`;

export const Value = styled.h3`
  display: inline-flex;
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.charcoal}
`;

export const Alert = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.redLight};
  padding: 12px 16px;
  border-radius: 10px;
  flex-direction: row;
  align-items: flex-start;
  
  img{
    margin-top: 4px;
  }
  
  span{
    flex: 1;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.red};
    margin-left: 8px;
  }
`;
