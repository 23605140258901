import React, { useState } from 'react';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formik';

import {
  Container, Label, Input, Touch, Row, Value, Card, ButtonLabel, ConfirmationButton, Alert, Loading
} from './styles';

import trash from '../../images/trash.svg'
import check from '../../images/check.svg'
import phone from '../../images/phone.svg'
import alert from '../../images/alert.svg'
import { formatAndExtractNumber } from "../../utils"
import client from "../../service/graphql"
import mutations from "../../service/mutations"

function PartnerForm({
  onSuccess, onDelete, item, number, deal,
}) {
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleDelete = async () => {
    setError(null);
    setLoading(true);
    const variables = {
      input: {
        deal: deal?.id,
        person: id,
      },
    };
    try {
      await client.request(mutations.DELETE_PARTICIPANT, variables)
      onDelete(item.id);
      setLoading(false);
    } catch (error) {
      const errorMessage = 'Ocorreu um erro inesperado';
      setError(errorMessage);
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setError(null)
    setLoading(true);

    const {
      phone_country_code,
      phone_number,
    } = formatAndExtractNumber(values.phone);

    const participant = {
      name: values.name,
      phone_country_code,
      phone_number,
    };

    const variables = {
      input: {
        deal: deal?.id,
        participant: {
          ...participant
        },
        origin: 'LP',
      },
    };

    try {
      const { createParticipant } = await client.request(mutations.CREATE_PARTICIPANT, variables)
      const ID = createParticipant.participant.person.id;
      setId(ID);
      onSuccess();
      setLoading(false);
    } catch (error) {
      const errorMessage = 'Ocorreu um erro inesperado';
      setError(errorMessage);
      setLoading(false);
    }
  };

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  if (item.done) {
    return (
      <Container>
        <Row>
          <Label>{`Parceiro ${number}`}</Label>
          <Touch onClick={handleDelete}>
            <img src={trash} />
            <ButtonLabel>Deletar</ButtonLabel>
          </Touch>
        </Row>
        <Card>
          <Value style={{ marginBottom: 18 }}>{formik.values.name}</Value>
          <Row>
            <img src={phone} style={{ marginRight: 8 }}/>
            <Value>{formik.values.phone}</Value>
            <img src={check} />
          </Row>
        </Card>
      </Container>
    );
  }

  return (
    <>
      {number <= 2 && 
        <Container>
          <Label style={{ marginBottom: 12, marginTop: 32 }}>{`Parceiro ${number}`}</Label>
          <Input
            placeholder="Digite o nome completo"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            error={formik.errors.name}
            helperText={formik.errors.name}
          />
          <Input
            icon={phone}
            placeholder="(DDD) 99999-9999"
            type="cel"
            mask="(99) 99999-9999"
            maskChar=""
            value={formik.values.phone}
            onChange={(e) => formik.setFieldValue('phone', e.target.value)}
            error={formik.errors.phone}
            helperText={formik.errors.phone}
          />
          {error && <Alert>
            <img src={alert} />
            <span>{error}</span>
          </Alert>}
          <ConfirmationButton
            disabled={!formik.isValid || loading}
            onClick={formik.submitForm}
          >
            <span>Clique aqui para confirmar</span>
            {loading && <Loading type="spin"  />}
          </ConfirmationButton>
        </Container>
      }
    </>
  );
}

export default PartnerForm;
