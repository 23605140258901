import React, { useState, useEffect } from "react"
import StepWizard from "react-step-wizard"
import { Redirect } from "@reach/router"
import styled from 'styled-components';

// GraphQL
import client from "../service/graphql"
import mutations from "../service/mutations"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import About from "../steps/about"
import Zap from "../steps/zap"
import Ocupafrom from "../steps/ocupation"
import Type from "../steps/type"
import Group from "../steps/group"
import Success from "../steps/success"
import Agree from "../steps/agree"
import Credit from "../steps/credit"
import Institution from "../steps/institution"
import { formatNumber, extractNumber } from "../utils"
import HeaderProgress from "../components/HeaderProgress"
import Indication from "../steps/indication"

const Container = styled.div`

`;

const StepContainer = styled.div`
  padding-top: ${({ padding }) => padding ? '78px': '0'};
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const FormPage = ({ location }) => {
  const [user, setUser] = useState()
  const [name, setName] = useState('Cadastro')
  const [description, setDescription] = useState('Sobre você')
  const [percent, setPercent] = useState(25)
  const [done, setDone] = useState(false)
  const [token, setToken] = useState(null)
  const [deal, setDeal] = useState(null)
  const [dealValue, setDealValue] = useState(null)
  const [trackingUtm, setTrackingUtm] = useState({});

  useEffect( () => {
    if(process.env.NODE_ENV === 'production'){
      window.gtag('event', 'conversion', {'send_to': 'AW-640390675/IOnSCKiwpNMBEJOsrrEC'})
    }
    setDealValue(location.state?.value);
    setTrackingUtm(location.state?.trackingUtm);
  }, [])

  if (!location.state) {
    return <Redirect noThrow to="/" />
  }

  function updateUser(newProps) {
    const { value } = location.state

    const payload = {
      ...user,
      ...newProps,
      value,
    }

    setUser(payload)
  }

  const submitAccount = async phone => {
    try {
      const formattedNumber = formatNumber(phone)
      const {
        phone_country_code,
        phone_number,
      } = extractNumber(formattedNumber)

      const { createAccount } = await client.request(mutations.CREATE_ACCOUNT, {
        input: {
          phone_country_code,
          phone_number,
          ...trackingUtm
        },
      })

      setToken(createAccount.token);
      return null
    } catch ({ response }) {
      return response.errors[0]
        ? response.errors[0].message
        : "ocorreu um erro inesperado"
    }
  }

  const createDeal = async () => {
    try{
      client.setHeader("authorization", `Bearer ${token}`)

      await client.request(mutations.CREATE_PERSON, {
        input: {
          name: user.name,
          occupation_area: user.ocupation,
        },
      })

      const { createDeal } = await client.request(mutations.CREATE_DEAL, {
        input: {
          value: dealValue,
          collective: true,
          split: 6
        },
      })

      setDeal(createDeal)

    }catch (e) {
      console.error(e)
    }
  }

  const handleStepChange = e => {
    const { activeStep } = e;

    switch (activeStep) {
      case 1:
        setName('Cadastro')
        setDescription('Sobre você')
        setPercent(25)
        break;
      case 2:
        setName('Cadastro')
        setDescription('Sobre você')
        setPercent(40)
        break;
      case 3:
        setName('Cadastro')
        setDescription('Seu grupo')
        setPercent(80)
        break;
      case 4:
        setName('Cadastro')
        setDescription('Seu grupo')
        setPercent(90)
        break;
      case 5:
        setName('Cadastro')
        setDescription('Indicar parceiro')
        setPercent(100)
        break;
      case 6:
        setDone(true)
        break;
    }
  }

  return (
    <Layout>
      <SEO title="Cadastro" />
      <Container>
        {!done && <HeaderProgress name={name} description={description} percent={percent} />}
        <StepContainer padding={!done}>
          <StepWizard onStepChange={handleStepChange}>
            <About lending={location.state.value} action={updateUser} />
            <Zap submitAccount={submitAccount} />
            <Agree lending={dealValue} action={createDeal} user={user} />
            <Group action={updateUser} user={user}/>
            <Indication deal={deal} />
            <Success />
          </StepWizard>
        </StepContainer>
      </Container>
    </Layout>
  )
}

export default FormPage
