import React, { useState } from "react"
import { navigate } from "gatsby"
import { Collapse } from "react-collapse"

import Photo from "../../images/consultor.png"
import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Button,
  BackButton,
  Alert,
  AlertLabel,
  AlertValue,
  Info,
  InfoContent,
  InfoHeader,
  Icon, Loading
} from "./styles"
import { Body, Buttons } from "../shared-styles"
import { toReal } from "../../utils/currency"

const Agree = ({ previousStep, nextStep, lending, action, user, goToStep}) => {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleAgree = async () => {
    setLoading(true)
    await action()
    setLoading(false)
    navigate("/form?group")
    nextStep()
  }

  const back = () => {
    navigate("/form?ocupation")
    previousStep()
  }

  const backConditional = () => {
    navigate("/form?credit-before")
    goToStep(4)
  }

  return (
    <Section>
      <Body>
        <Header>
          <Profile>
            <User src={Photo} />
          </Profile>
          <Label><p>A solicitação Veriza é em grupo, os parceiros são responsáveis pelos créditos uns dos outros.</p></Label>
        </Header>
        <Form>
          <Alert>
            <AlertLabel>Valor de solicitação por parceiro:</AlertLabel>
            <AlertValue>{toReal(lending)}</AlertValue>
          </Alert>
          <Info>
            <InfoHeader>
              <p>Não entendi, me explique mais</p>
              <button
                className={expanded && 'expanded'}
                onClick={() => setExpanded(!expanded)}
              >
                <Icon />
              </button>
            </InfoHeader>
            <Collapse isOpened={expanded}>
              <InfoContent>
                <p>Você e seus parceiros são corresponsáveis pelo empréstimo. Caso alguém não pague as parcelas em dia, as outras pessoas serão responsbilizadas pelo pagamento. Assim conseguimos um crédito melhor para você!</p>
              </InfoContent>
            </Collapse>
          </Info>
          {loading && <Loading />}
        </Form>
      </Body>
      <Buttons>
        <BackButton onClick={() => user.credit=="no" ? backConditional() : back()}>Voltar</BackButton>
        <Button disabled={loading} onClick={handleAgree}>
          Entendi e concordo
        </Button>
      </Buttons>
    </Section>
  )
}

export default Agree
