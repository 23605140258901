import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  margin-bottom: 15px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    align-items: center;
    flex-direction: row;
  `}
`

export const Logo = styled.img`
  margin-left: 25px;
  src: url(${props => props.src});
  width: 36px;
  height: 35px;

  ${media.greaterThan("medium")`
    margin-left: 76px;
  `}
`

export const Description = styled.p`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.67px;
  color: ${props => props.theme.colors.charcoal};
`

export const Section = styled.section`
  display: flex;
  height: calc(100vh - 78px);
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan("medium")`
    width: 450px;
    margin: auto;
    justify-content: flex-start;
  `}
`

export const Profile = styled.div`
  margin-top: 50px;
  margin-left: 30px;
  width: 20%;
  height: 60px;

  ${media.lessThan("medium")`
  width: 60px;
  height: 60px;
  margin-top: 25px;
  margin-left: 20px;
`}
`

export const User = styled.img`
  src: url(${props => props.src});
`

export const Form = styled.div`
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 30px;
`

export const Label = styled.div`
  margin-top: 12px;
  margin-left: 30px;
  margin-right: 30px;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  span {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  ${media.lessThan("medium")`
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 28px;
    width: 61%;
  `}
`

export const Icon = styled.p`
  align-self: center;
`

export const Button = styled.button`
  width: 98px;
  height: 48px;
  border-radius: 10px;
  border-style: outset;
  border: none;
  background-color: ${props => props.theme.colors.green};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  &:disabled {
    background-color: ${props => props.theme.colors.greenLighter};
    opacity: 0.6;
  }

  &:hover {
    background-color: ${props => props.theme.colors.greenDark};
  }
`

export const BackButton = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  cursor: pointer;
`

export const InstitutionSelect = styled.select`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: solid 1px;
  background: #FFFFFF;
  border: 1px solid rgba(44, 45, 44, 0.3);
  margin-top: 20px;
  text-align-last: center;

  option {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.greaterThan("medium")`
    width: 390px;
    margin-top: 35px;
  `} 
`