import React, { useState } from "react"
import { navigate } from 'gatsby'
import Photo from "../../images/consultor.png"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  InstitutionSelect,
  Button,
  BackButton,
} from "./styles"
import { Body, Buttons } from "../shared-styles"

const Institution = ({ nextStep, previousStep, action, lastStep }) => {
  const [institution, setInstitution] = useState('')

  function submit() {
    action({ institution })
    navigate("/form?confirmation")
    return nextStep()
  }

  function selectInstitution(e) {
    setInstitution(e.target.value)
  }

  function back() {
    navigate("/form?credit-before")
    previousStep()
  }

  return (
    <Section>
      <Body>
        <Header>
          <Profile>
            <User src={Photo} />
          </Profile>
          <Label>
            <p>Com qual instituição?</p>
          </Label>
        </Header>
        <Form>
          <InstitutionSelect value={institution} onChange={selectInstitution} placeholder="Selecionar">
            <option value="Movera(BB)">Movera(BB)</option>
            <option value="Prospera(Santender)">Prospera(Santender)</option>
            <option value="Providência">Providência</option>
            <option value="Crediamigo(BNB)">Crediamigo(BNB)</option>
            <option value="Caixa Crescer(CAIXA)">Caixa Crescer(CAIXA)</option>
            <option value="Acreditar(BRB)">Acreditar(BRB)</option>
          </InstitutionSelect>
        </Form>
      </Body>
      <Buttons>
        <BackButton onClick={back}>Voltar</BackButton>
        <Button disabled={!institution} onClick={() => submit()}>
          Próximo
        </Button>
      </Buttons>
    </Section>
  )
}

export default Institution
