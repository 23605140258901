import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  BackButton,
} from "./styles"
import { Body, Buttons } from "../shared-styles"

const Ocupation = ({ previousStep, nextStep, action }) => {
  const [ocupation, setOcupation] = useState("")

  const options = [
    { value: "prestacao-de-servicos", label: "Prestação de serviços" },
    { value: "comercio-em-geral", label: "Comercio em geral" },
    {
      value: "artesanato-e-fabricacao-de-itens ",
      label: "Artesanato e fabricação de itens ",
    },
    { value: "producao-rural", label: "Produção rural" },
  ]

  function submit() {
    action({ ocupation })
    navigate("/form?credit-before")
    nextStep()
  }

  function back() {
    navigate("/form?phone")
    previousStep()
  }

  return (
    <Section>
      <Body>
        <Header>
          <Profile>
            <User src={Photo} />
          </Profile>
          <Label>
            <p>Última pergunta sobre você. <span>Qual é a sua área de atuação?</span></p>
          </Label>
        </Header>
        <Form>
          <Input
            value={ocupation.value}
            onChange={({ target }) => {
              setOcupation(target.value)
            }}
          >
            <option value={null}>Selecione</option>
            {options.map(ocupation => (
              <option key={ocupation.value} value={ocupation.value}>
                {ocupation.label}
              </option>
            ))}
          </Input>
        </Form>
      </Body>
      <Buttons>
        <BackButton onClick={back}>Voltar</BackButton>
        <Button disabled={ocupation.value === null} onClick={() => submit()}>
          Próximo
        </Button>
      </Buttons>
    </Section>
  )
}

export default Ocupation
