import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import AlertIcon from "../../images/fill-1.png"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  BackButton,
} from "./styles"

import Alert from "../../components/alert"
import { Body, Buttons } from "../shared-styles"

const Zap = ({ previousStep, nextStep, submitAccount }) => {
  const [phone, setPhone] = useState("")
  const [error, setError] = useState(null)

  async function submit(e) {
    e.preventDefault()
    setError(null)
    const error = await submitAccount(phone)

    if (error) {
      return setError(error)
    }
    navigate("/form?ocupation")
    nextStep()
  }

  function back() {
    navigate("/form?name")
    previousStep()
  }

  return (
    <form onSubmit={submit}>
      <Section>
        <Body>
          <Header>
            <Profile>
              <User src={Photo} />
            </Profile>
            <Label><p>E qual é o seu telefone? <span>Pode ser WhatsApp.</span></p></Label>
          </Header>
          <Form>
            <Input
              placeholder="(DD) 99999-9999"
              onChange={({ target }) => setPhone(target.value)}
              mask="(99) 99999-9999"
              maskChar=""
              type="tel"
            ></Input>
          </Form>
          {error && <Alert icon={AlertIcon} description={error}></Alert>}
        </Body>
        <Buttons>
          <BackButton onClick={back}>Voltar</BackButton>
          <Button type="submit" disabled={phone.length < 15} >
            Próximo
          </Button>
        </Buttons>
      </Section>
    </form>
  )
}

export default Zap
