import React from "react"
import { Container, Text } from "./styles"

export default function Alert({ icon, description }) {
  return (
    <Container>
      <img src={icon} />
      <Text>{description}</Text>
    </Container>
  )
}
