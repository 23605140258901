import styled from "styled-components"
import InputMask from "react-input-mask"
import media from "styled-media-query"

export const Container = styled.div`
  margin-bottom: 15px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    align-items: center;
    flex-direction: row;
  `}
`

export const Section = styled.section`
  display: flex;
  height: calc(100vh - 78px);
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan("medium")`
    width: 450px;
    margin: auto;
    justify-content: flex-start;
  `}
`

export const Profile = styled.div`
  margin-top: 50px;
  margin-left: 30px;
  width: 60px;
  height: 60px;

  ${media.lessThan("medium")`
    width: 60px;
    height: 60px;
    margin-top: 25px;
    margin-left: 28px;
  `}
`

export const User = styled.img`
  src: url(${props => props.src});
`

export const Form = styled.div`
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 108px;
  
  .card{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    padding: 15px 12px;
    border: 1px solid rgba(44, 45, 44, 0.1);
    box-shadow: 1px 3px 12px rgba(44, 45, 44, 0.08);
    border-radius: 10px;
    
    .info{
      display: flex;
      flex-direction: row;
      
      span{
        margin-left: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.charcoal};
        opacity: 0.6;      
      }
    }
    
    h3.name{
      margin-top: 5px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;     
      
      color: ${({ theme }) => theme.colors.charcoal};
    }
  }
  
  .add-partner{
    margin-top: 12px;
    width: 100%;
    height: 64px;
    background-color: white;
    border: 1px solid rgba(44, 45, 44, 0.15);
    box-shadow: 0px 2px 4px rgba(44, 45, 44, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 20px;
    
    span{
      font-family: Poppins;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.charcoal};
    }
  }
`

export const Label = styled.div`
  margin-top: 12px;
  margin-left: 15px;
  margin-right: 30px;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  span {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: ${props => props.theme.colors.charcoal};
  }

  ${media.lessThan("medium")`
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 28px;
    width: 61%;
  `}
`

export const Button = styled.button`
  width: 98px;
  height: 48px;
  border-radius: 10px;
  border-style: outset;
  border: none;
  background-color: ${props => props.theme.colors.green};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  &:disabled {
    background-color: ${props => props.theme.colors.greenLighter};
    opacity: 0.6;
  }

  &:hover {
    background-color: ${props => props.theme.colors.greenDark};
  }
`

export const BackButton = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  cursor: pointer;
`

export const Friends = styled.div`
  margin-top: 32px;
  margin-bottom: 60px;
`

export const Invites = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  width: 319px;

  span {
    font-weight: bold;
  }
`

export const More = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.greenDark};
`

export const FrindBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-bottom: 32px;
`

export const FrindNumber = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors.charcoal};
`

export const FrindName = styled.input`
  padding-left: 15px;
  width: 319px;
  height: 48px;
  margin-top: 16px;
  border-radius: 10px;
  border: solid 1px rgba(44, 45, 44, 0.3);
  background-color: #ffffff;
`

export const FrindPhone = styled(InputMask)`
  padding-left: 15px;
  width: 319px;
  height: 48px;
  margin-top: 16px;
  border-radius: 10px;
  border: solid 1px rgba(44, 45, 44, 0.3);
  background-color: #ffffff;
`

export const Success = styled.div`
  width: 319px;
  height: 132px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.greenLighter};

  p {
    padding-top: 18px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.theme.colors.charcoal};

    span {
      font-weight: bold;
    }
  }
`

export const GroupDisclaimer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 319px;
  height: 52px;
  border-radius: 10px;
  background-color: rgba(44, 45, 44, 0.08);
  padding: 0px 16px 0px 16px;
  margin-bottom: 16px;
`

export const GroupDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

`

export const GroupValue = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
`

export const Validate = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.red};
  text-align: end;
  margin-top: 10px;
`;
