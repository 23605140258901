import React, { useState } from 'react';

import {
  Container, HelperText, InputContainer, Loading, MaskInput, Label,
} from './styles';

import close from '../../images/close.svg'
import check from '../../images/check.svg'

function TextInput({
  error, warning, helperText, loading, className, icon, type, label,
  inputStyle, success, ...props
}) {
  const [focused, setFocused] = useState(false);

  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <InputContainer focused={focused} style={inputStyle}>
        {icon && <img className="icon" src={icon} /> }
        <MaskInput
          type={type}
          style={{ marginLeft: icon ? 8 : 16 }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...props}
        />
        {error && <img src={close} />}
        {success && <img src={check} />}
        {loading && <Loading />}
      </InputContainer>
      {error && (
        <HelperText>
          {helperText}
        </HelperText>
      )}
      {warning && (
        <HelperText>
          {helperText}
        </HelperText>
      )}
    </Container>
  );
}

export default TextInput;
