import styled from 'styled-components';
import InputMask from "react-input-mask";
import ReactLoading from 'react-loading';


export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  padding-right: 12px;
  border: 1px solid ${({ focused, theme }) => (focused ? theme.colors.charcoal : theme.colors.charcoal03)};
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
    
  .icon{  
    margin-left: 12px;
  }
`;

export const MaskInput = styled(InputMask)`
  flex: 1;
  font-family: Poppins;
  font-weight: 500;
  height: 100%;
  border: none;
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  align-self: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  
  &:focus ~ .input-container{    
    border: 1px solid red;
  }
`;

export const HelperText = styled.span`
  margin-right: 16px;
  margin-top: 4px;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.red};
  align-self: flex-end;
`;

export const Loading = styled(ReactLoading).attrs({
  type: 'spin',
  color: '#2c2d2c',
  height: 16,
  width: 16,
})``;

export const Label = styled.span`
  font-size: 16px;
  font-family: Poppin;
  font-weight: bold;
  letter-spacing: 0.53px;
  color: ${({ theme }) => theme.colors.charcoal};
  margin-bottom: 8px;
`;
